import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { ProjectSummary } from "../../components/projects/ProjectSummary";

const BrooksideSubdivision = () => (
  <Layout>
    <SEO
      title="Mains Water and Fire Hydrant Replacement at Tararua College"
      image={"/tararua.jpg"}
      description="The works involved replacement of the mains water and fire hydrant networks at Tararua College. The scope included the excavation and removal of existing potable water pipe work and construction of a new potable water network, including all testing and commissioning."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Mains Water and Fire Hydrant Replacement at Tararua College
          </h1>
          <ProjectSummary
            name="Mains Water and Fire Hydrant Replacement at Tararua College"
            location="Tararua College, Pahiatua"
            client="The Board of Trustees for Tararua College"
            engineer="The Board of Trustees for Tararua College"
          />
          <p>
            The works involved replacement of the mains water and fire hydrant
            networks at Tararua College. The scope included the excavation and
            removal of existing potable water pipe work and construction of a
            new potable water network, including all testing and commissioning.
            Works also included excavation and removal of existing fire hydrants
            and pipe work and installation of a new fire hydrants and associated
            network, including all testing and commissioning.
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div style={{ maxWidth: 613 }}>
          <StaticImage
            className="mb-3"
            aspectRatio={16 / 10}
            transformOptions={{ cropFocus: "center" }}
            objectPosition="50% 50%"
            src="../../images/tararua.jpg"
            alt="mains water"
          />
          <StaticImage
            aspectRatio={16 / 10}
            transformOptions={{ cropFocus: "center" }}
            objectPosition="50% 50%"
            src="../../images/tararua2.jpg"
            alt="mains water"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default BrooksideSubdivision;
